import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {UserToken} from "../../models/auth.model";
import * as AccessSelectors from "../../store/selectors/access.selector";
import * as AccessActions from "../../store/actions/access.action";
import {AppState} from "../../store/appstate.model";

@Injectable({
    providedIn: 'root',
})
export class AccessFacadeService {
    private static readonly TAG = 'AccessFacadeService';

    public constructor(private store: Store<AppState>) {}

    /**
     * Observable stream of access token from the store.
     */
    public userTokens$ = this.store.select(AccessSelectors.selectAccessToken);

    /**
     * Dispatches an action to load the access token into the store.
     */
    public loadData(): void {
        this.store.dispatch(AccessActions.loadUserToken());
    }

    /**
     * Dispatches an action to set the whole user token in the store.
     * The user token is the object that comes from the API on login and includes, among other stuff, access_token and refresh_token
     * @param token The UserToken object to be added or updated.
     */
    public setUserToken(token: UserToken): void {
        this.store.dispatch(AccessActions.setUserToken({ token }));
    }

    /**
     * Dispatches an action to set the access_token property in the user token.
     */
    public setAccessToken(accessToken: string): void {
        this.store.dispatch(AccessActions.setAccessToken({ accessToken }));
    }

    /**
     * Dispatches an action to remove the whole user token in the store.
     * The user token is the object that comes from the API on login and includes, among other stuff, access_token and refresh_token
     * @param token The UserToken object to be removed.
     */
    public  removeUserToken(): void {
        this.store.dispatch(AccessActions.removeUserToken());
    }
}
