import { Component, Inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loading-dialog',
    standalone: true,
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.scss'],
    imports: [MatProgressSpinnerModule, MatCardModule],
})
export class LoadingDialogComponent implements OnInit {
    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: { message: string }
    ) {}

    public ngOnInit() {}
}
