import {MatDialog} from '@angular/material/dialog';
import {Injectable} from '@angular/core';
import {LoadingDialogComponent} from '../modals/loading-dialog/loading-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ToolboxService {
    private static readonly TAG = 'ToolboxService';

    public constructor(private dialog: MatDialog) {
    }

    public async executeInLoading(f: () => Promise<any>, message?: string, minTime: number = 0): Promise<any> {
        const dialogRef = this.dialog.open(LoadingDialogComponent, {
            disableClose: true,
            data: {message}
        });

        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        const resultPromise = f();
        const timerPromise = delay(minTime);

        try {
            const result = await Promise.all([resultPromise, timerPromise]);
            return result[0];
        } finally {
            dialogRef.close();
        }
    }

    public downloadTextFile(content: string, fileName: string): void {
        const blob = new Blob([content], {type: 'text/plain'});

        const link = document.createElement('a');

        // Step 3: Create a URL for the Blob and set it as the href attribute
        const url = window.URL.createObjectURL(blob);
        link.href = url;

        // Step 4: Set the download attribute with the desired file name
        link.download = fileName;

        // Step 5: Append the link to the document body (required for Firefox)
        document.body.appendChild(link);

        // Step 6: Programmatically click the link to trigger the download
        link.click();

        // Step 7: Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
}
